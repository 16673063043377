<template>
  <div class="wrap-history">
    <div class="container-history">
      <TopNav
        :title="'history_event'"
        :navList="permissionNavList"
        v-model="activeComp"
        @goTo="handleNav"
      />

      <div class="history-content">
        <transition>
          <component :is="activeComp"></component>
        </transition>
      </div>
    </div>
    <HistoryEventModal v-if="historyEventModalShow" />
    <ExportInfoModal v-if="showExportInfoModal" />
    <AllowAudio v-if="showAllowAudio" @closemodal="closeAllowAudioModal" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'History',
  components: {
    TopNav: () => import(`@/components/tools/TopNav.vue`),
    HistoryLpr: () => import('@/components/History/HistoryLpr.vue'),
    HistoryFr: () => import('@/components/History/HistoryFr.vue'),
    HistoryUrgent: () => import('@/components/History/HistoryUrgent.vue'),
    HistoryVideo: () => import('@/components/History/HistoryVideo.vue'),
    HistoryEventModal: () => import('@/components/History/HistoryEventModal.vue'),
    ExportInfoModal: () => import('@/components/History/ExportInfoModal.vue'),
    AllowAudio: () => import('@/components/AllowAudio.vue'),
  },
  data() {
    return {
      navList: [
        {
          navTitle: 'history_event_lpr'/*車輛*/,
          comp: 'HistoryLpr',
          icon: require('@/assets/icons/LPR.svg'),
          accessFlag: 'lprEventAccess'
        },
        {
          navTitle: 'history_event_fr'/*人物*/,
          comp: 'HistoryFr',
          icon: require('@/assets/icons/FR.svg'),
          accessFlag: 'frEventAccess'
        },
        // {
        //   navTitle: 'history_event_frid'/*身份識別*/,
        //   comp: 'HistoryFrid',
        //   icon: require('@/assets/icons/person-checked.svg')
        // },
        {
          navTitle: 'event_tab_urgent' /*緊急*/,
          comp: 'HistoryUrgent',
          icon: require('@/assets/icons/urgent-white.svg'),
          accessFlag: 'lprEventAccess' // todo: 先用LPR事件權限
        },
        // {
        //   navTitle: 'event_tab_video' /*影片*/,
        //   comp: 'HistoryVideo',
        //   icon: require('@/assets/icons/video.svg'),
        //   accessFlag: 'videoManagement'
        // },
      ],
      permissionNavList: [],
      activeComp: null
    }
  },
  computed: {
    ...mapState(['permissionV2', 'showAllowAudio']),
    ...mapState('history', ['historyEventModalShow', 'showExportInfoModal']),
  },
  created() {
    this.getGroupList()
  },
  mounted() {
    this.permissionNavList = this.navList.filter(item => this.permissionV2[item.accessFlag] > 0)
    if (this.permissionNavList.length === 0) {
      this.activeComp = null
      return
    }
    this.activeComp = this.permissionNavList[0].comp
  },
  beforeDestroy() {
    this.updateHistoryEventModalShow(false)
  },
  methods: {
    ...mapMutations('history', ['updateHistoryEventModalShow']),
    ...mapActions(['getGroupList']),
    closeAllowAudioModal() {
      this.$store.commit('updateShowAllowAudio', false)  // 之後不需再顯示跳窗詢問
    },
    handleNav(comp) {
      this.activeComp = comp
    }
  },
}
</script>

<style lang="scss" scoped>
.wrap-history {
  // width: 100vw;
  // flex-grow: 1;
  width: calc(100% - 50px);
  height: 100vh;
  background: #151B35;
}

.container-history {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 110px calc(100% - 110px);
  grid-template-columns: 0 100%;
  grid-template-areas: 
  "navbar searchbar" 
  "navbar map"; */
}

// .history-navbar {
//   display: flex;
//   width: 100%;
//   height: 60px;
//   background: #151B35;
//   padding: 0 40px;
//   .title {
//     font-size: 36px;
//     font-weight: 500;
//     line-height: 60px;
//     color: #ffffff;
//     margin-right: 62px;
//     user-select: none;
//   }

//   .navbar {
//     display: flex;
//     column-gap: 52px;
//     .nav {
//       display: flex;
//       align-items: center;
//       font-size: 24px;
//       font-weight: 300;
//       line-height: $NavH;
//       color: #ffffff;
//       border-bottom: #151B35 5px solid;
//       box-sizing: border-box;
//       user-select: none;
//       cursor: pointer;
//       // transition: all 0.3s;
//       &.active {
//         color: #FFC000;
//         border-color: #FFC000;
//       }
//       img {
//         margin-right: 8px;
//       }
//       &.active img {
//         filter: brightness(0) saturate(100%) invert(87%) sepia(23%) saturate(6232%) hue-rotate(357deg) brightness(100%) contrast(103%);
//       }
//     }
//   }
// }
.history-navbar {
  display: flex;
  width: 100%;
  height: 70px;
  background: #151B35;
  padding: 0 40px;
  .title {
    font-size: px2rem(36);
    font-weight: 500;
    line-height: 70px;
    color: #ffffff;
    margin-right: 62px;
    user-select: none;
  }

  .navbar {
    display: flex;
    column-gap: 52px;
    .nav {
      display: flex;
      align-items: center;
      font-size: px2rem(24);
      font-weight: 300;
      line-height: 70px;
      color: #ffffff;
      padding-top: 5px;
      border-bottom: #151B35 5px solid;
      box-sizing: border-box;
      user-select: none;
      cursor: pointer;
      // transition: all 0.3s;
      &.active {
        color: #FFC000;
        border-color: #FFC000;
      }
      img {
        margin-right: 8px;
      }
      &.active img {
        filter: brightness(0) saturate(100%) invert(87%) sepia(23%) saturate(6232%) hue-rotate(357deg) brightness(100%) contrast(103%);
      }
    }
  }
}

.history-content {
  width: 100%;
  height: calc(100% - px2rem($NavH));
}

.navbar {
  grid-area: navbar;
}

.search-bar {
  grid-area: searchbar;
}

.map-area {
  grid-area: map;
}
</style>
